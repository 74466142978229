import AppScaffold from "../../components/ui/AppScaffold";
import {useCallback, useEffect, useMemo, useState} from "react";
import Markdown from "react-markdown";
import {useTranslation} from "react-i18next";
import rehypeRaw from 'rehype-raw'

const ScreenStaticText = () => {

    const url = window.location.pathname

    const {t} = useTranslation()

    const [pageBody, setPageBody] = useState<string>("")

    const pageTitle = useMemo(() => {
        return t(`static.${url.replace('/', '')}`)
    }, [url, t])

    const getPageBody = useCallback(async () => {
        try {
            let fileName;
            switch (url) {
                case '/about':
                    fileName = 'about.md'
                    break;
                case '/privacy-policy':
                    fileName = 'privacy-policy.md'
                    break;
                case '/general-terms-of-sale':
                    fileName = 'general-terms-of-sale.md'
                    break;
                case '/terms-of-use':
                    fileName = 'terms-of-use.md'
                    break;
                case '/how-to-buy-clipme':
                    fileName = 'how-to-buy-clipme.md'
                    break;
                case '/selling-clipme':
                    fileName = 'selling-clipme.md'
                    break;
                case '/what-clipme-are':
                    fileName = 'what-clipme-are.md'
                    break;
            }
            const response = fetch(require('../../documents/' + fileName))
            const data = await response.then((res) => res.text())
            setPageBody(data)
        } catch (e) {

        }
    }, [url])

    useEffect(() => {
        getPageBody()
    }, [url]);


    return (<AppScaffold menuPosition={"relative"} menuStyle={"normal"}>
        <>
            <div className={"py-28 px-20 text-center bg-createPage bg-cover bg-top"}>
                <h1 className={"h1 text-white pb-4"}>{pageTitle}</h1>
            </div>
            <section className={"app-container py-10"}>
                <div className={"lg:w-8/12 lg:ml-12"}>
                <Markdown
                        rehypePlugins={[rehypeRaw]}
                        components={{
                            p: ({node, ...props}) => <p className={"mb-2"} {...props} />,
                            h2: ({node, ...props}) => <h2 className={"mb-4"} {...props} />,
                            h3: ({node, ...props}) => <h3 className={"mb-4"} {...props} />,
                        }}>{pageBody}</Markdown>
                </div>
            </section>
        </>
    </AppScaffold>)
}

export default ScreenStaticText